<template>
  <form id="formCadastroParcerias" action="" method="post" class="form-custom bg-white p-5" :class="{ 'was-validated': wasValidated}" @submit="checkForm">
    <input v-if="this.isUpdating" type="hidden" name="id" :value="registry.id" >
    <fieldset class="row w-100" :disabled="sendingState || readOnlyMode">
      <div class="col-6 mb-3">
        <label for="inputnome_do_programa" class="form-label fw-bold">Programa</label>
        <input type="text" class="form-control" id="inputnome_do_programa" name="nome_do_programa" placeholder="Digite aqui" value="Jovem de Futuro" readonly required>
        <div class="invalid-feedback">
          O nome do programa é obrigatório
        </div>
      </div>
      <div class="col-6 mb-3">
        <label for="inputnome_da_acao" class="form-label fw-bold">Nome da ação</label>
        <input type="text" class="form-control" id="inputnome_da_acao" name="nome_da_acao" placeholder="Digite aqui" required>
        <div class="invalid-feedback">
          O nome do ação é obrigatório
        </div>
      </div>
      <div class="col-12 mb-3">
        <label for="inputlista_de_parceiros" class="form-label fw-bold">Parceiros</label>
        <!-- <textarea class="form-control" id="inputlista_de_parceiros" name="lista_de_parceiros" rows="6" placeholder="Digite aqui o nome do parceiro" required></textarea> -->
        <ActorSearch :badgeClickCallback="addSelected" :registry="registry" />
        <input type="hidden" name="lista_de_parceiros" id="inputlista_de_parceiros" :value="actorSelected">
      </div>
      <div class="col-6 mb-3">
        <label for="inputdata_inicio" class="form-label fw-bold">Data de início</label>
        <input type="text" class="form-control" id="inputdata_inicio" name="data_inicio" placeholder="00/00/2022" onkeyup="
        var v = this.value;
        if (v.match(/^\d{2}$/) !== null) {
            this.value = v + '/';
        } else if (v.match(/^\d{2}\/\d{2}$/) !== null) {
            this.value = v + '/';
        }"
    maxlength="10">
      </div>
      <div class="col-6 mb-3">
        <label for="inputdata_conclusao" class="form-label fw-bold">Data de término</label>
        <input type="text" class="form-control" id="inputdata_conclusao" name="data_conclusao" placeholder="00/00/2022" onkeyup="
        var v = this.value;
        if (v.match(/^\d{2}$/) !== null) {
            this.value = v + '/';
        } else if (v.match(/^\d{2}\/\d{2}$/) !== null) {
            this.value = v + '/';
        }"
    maxlength="10">
      </div>

      <div class="col-6 mb-3">
        <label class="form-label fw-bold">Status</label>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="status" id="inputstatus1" value="em-andamento" required>
          <label class="form-check-label" for="inputstatus1">
            Em andamento
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="status" id="inputstatus2" value="pausada" required>
          <label class="form-check-label" for="inputstatus2">
            Pausada
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="status" id="inputstatus3" value="concluida" required>
          <label class="form-check-label" for="inputstatus3">
            Concluída
          </label>
        </div>
      </div>
      <div class="col-6 mb-3">
        <label for="inputinformacoes_adicionais" class="form-label fw-bold">Informações adicionais</label>
        <textarea class="form-control" id="inputinformacoes_adicionais" name="informacoes_adicionais" rows="6" placeholder="Digite aqui algo que você deseja acrescentar"></textarea>
      </div>
    </fieldset>
    <div class="row w-100 justify-content-center mt-5">
      <button v-if="!readOnlyMode" type="submit" class="col-4 mx-1 btn btn-primary rounded-pill" :disabled="sendingState" @click="wasValidated = true">
        <span v-if="sendingState">Registrando ... <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></span>
        <span v-else>Associar parceria</span>
      </button>
    </div>
  </form>

  <div class="modal-mask d-flex justify-content-center align-items-center" v-if="showModal" @close="showModal = false">
      <modal v-if="vinculoAtor === true">
        <div class="row">
          <div class="col-6"></div>
          <div class="col-6 d-flex justify-content-end" style="padding-top: 10px; padding-right: 20px;">
            <button class="btn" @click="showModal = false">
              <img src="../../assets/icon-close.png" alt="">
            </button>
          </div>
        </div>
        <h3 style="padding: 0 3em;">Cadastro realizado com sucesso</h3>
        <div class="container_box">
          <p class="p-4">Agradecemos as suas informações</p>
          <div class="d-flex justify-content-between pb-5 pt-3">
            <router-link class="nav-link mb-1 text-secondary bg-gray-100" :class="{ 'active': /^Parcerias.*$/.test($route.name) }" :to="{ name: 'Parcerias' }">
              <button class="btn btn-outline-primary px-3 rounded-pill" @click="showModal = false">
                  Ir para lista de Parcerias
              </button>
            </router-link>
            <router-link class="nav-link mb-1 text-secondary bg-gray-100" :class="{ 'active': /^Parcerias.*$/.test($route.name) }" :to="{ name: 'Parcerias.Cadastro' }">
              <button class="btn btn-primary px-4 py-2 rounded-pill" @click="showModal = false, reloadPage()">
                  Fazer novo cadastro
              </button>
            </router-link>
          </div>
        </div>
      </modal>
      <modal v-if="vinculoAtor === false">
        <div class="row">
          <div class="col-6"></div>
          <div class="col-6 d-flex justify-content-end" style="padding-top: 10px; padding-right: 20px;">
            <button class="btn" @click="showModal = false">
              <img src="../../assets/icon-close.png" alt="">
            </button>
          </div>
        </div>
        <div class="container_box">
          <h3 style="padding: 0 3em;">Alerta!</h3>
          <p class="p-4">É necessário vincular pelo menos 1 ator a parceria</p>
        </div>
      </modal>
  </div>
</template>

<script>
/*eslint-disable */

import { ParceriasRepository } from '../../libs/repositories';
import ActorSearch from '../../components/ActorSearch.vue';
import moment from 'moment'

export default {
  name: 'FormParceria',
  props: {
    registry: {
      type: Object,
      required: false,
      default: null
    },
    readOnlyMode: {
      type: Boolean,
      requried: false,
      default: false
    }
  },
  components: {
    ActorSearch
  },
  data () {
    return {
      isUpdating: !!this.registry,
      sendingState: false,
      wasValidated: false,
      actorSelected: [],
      showModal: false,
      parceiros: [],
      vinculoAtor: true
    };
  },
  mounted () {
    if (!this.isUpdating) return;

    /** @type {HTMLFormElement} */ // @ts-expect-error
    const form = document.getElementById('formCadastroParcerias');
    this.registry.lista_de_parceiros.forEach(element => {
      this.parceiros.push(element.ator_id);
    });

    form.nome_do_programa.value = this.registry.nome_do_programa;
    form.nome_da_acao.value = this.registry.nome_da_acao;
    form.status.value = this.registry.status;
    form.data_inicio.value = moment(this.registry.data_inicio).format('DD/MM/YYYY');
    form.data_conclusao.value = this.registry.data_conclusao === null ? null : moment(this.registry.data_conclusao).format('DD/MM/YYYY');
    form.informacoes_adicionais.value = this.registry.informacoes_adicionais;
    form.lista_de_parceiros.value = this.actorSelected.concat(this.parceiros);
  },
  methods: {
    /**
     * @param {Event} event
     */
    async checkForm (event) {
      event.preventDefault();

      /** @type {HTMLFormElement} */ // @ts-expect-error
      const form = event.target;
      const formData = new FormData(form);

      let result = moment(form.data_conclusao.value).isValid();

      if(form.lista_de_parceiros.value === '' && !this.isUpdating){
        this.wasValidated = false;
        this.showModal = true;
        this.vinculoAtor = false;
        return;
      } else{
        this.vinculoAtor = true;
      }
      if(form.status.value === 'concluida'){
        form.data_conclusao.required = true;
      }
      if(form.status.value === 'concluida' && result !== true){
        this.wasValidated = true;
        return;
      }
      
      
      if (!form.checkValidity()) {
        this.wasValidated = true;
        return;
      }

      this.sendingState = true;

      const model = {};

      formData.forEach((value, key) => {
        // @TODO para os primeiros teste fica assim
        if (key === 'lista_de_parceiros') {
          model[key] = value.toString().split(',').concat(this.parceiros).filter(function (i) {
            return i;
          }); ;
        } else {
          model[key] = value;
        }
        if (this.isUpdating) {
          if (key === 'data_inicio') {
            model[key] = this.formatarData(value.toString());
          }
          if (key === 'data_conclusao') {
            model[key] = this.formatarData(value.toString());
          }
        } else if (!this.isUpdating) { //Removendo as condições de this.Updating e deixando apenas as condições da key, inverte dia com o mês, verificar pq com tempo.
          if (key === 'data_inicio') {
            model[key] = this.formatarData(value.toString());
          }
          if (key === 'data_conclusao') {
              model[key] = this.formatarData(value.toString());
          }
        }
      });
      const saved = (this.isUpdating)
        ? await ParceriasRepository.update(model.id, model)
        : await ParceriasRepository.add(model);

      this.sendingState = false;
      this.wasValidated = false;

      if (!this.isUpdating && saved) {
        form.reset();
      }

      this.showModal = true;
      // eslint-disable-next-line
      console.log(saved ? 'sucesso' : 'duplicado');
    },
    formatarData (data) {
      // da "split" no 'espaço'
      var d = data.split(' ')[0]; // recupera 23/03/2012
      var dsplit = d.split('/'); // recupera o array(23, 03, 2012);
      // cria o objeto date
      var novaData = new Date(dsplit[2], dsplit[1] - 1, dsplit[0]);
      // retorna o objeto date
      return novaData;
    },
    addSelected (actor) {
      this.actorSelected.push(actor.id);
    },
    reloadPage () {
      window.location.reload();
    }
  }
};
</script>
<style scoped>
  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
  }
  modal {
    background-color: white;
    display: block;
    max-width: 540px;
    width: 100%;
    border-radius: 5px;
    text-align: center;
  }
</style>
